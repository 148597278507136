import React, { ReactNode } from "react";
import styles from "./main-content.module.scss";

interface Props {
  children: ReactNode;
}

export function MainContent({ children }: Props) {
  return <div className={styles.content}>{children}</div>;
}
