import React from "react";
import styles from "./store-notification.module.scss";
import googlePlay from "../../../assets/logos/google-dark.svg";
import appStore from "../../../assets/logos/app-store-dark.svg";
import ruStore from "../../../assets/logos/ru-store-dark.svg";
import nashStore from "../../../assets/logos/nash-store-dark.svg";
import { Dialog } from "../ui-kit/Dialog";

export const StoreNotification = () => {
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);

  React.useEffect(() => {
    const width = window.screen.width;
    width <= 767 ? setIsOpenDialog(true) : setIsOpenDialog(false);
  }, []);
  const stores = [
    {
      name: "Google Play",
      href: "https://play.google.com/store/apps/details?id=ru.stmlabs.procheck.app",
      icon: googlePlay,
    },
    {
      name: "App Store",
      href: "https://apps.apple.com/us/app/%D0%BF%D1%80%D0%BE%D1%87%D0%B5%D0%BA/id1587687415",
      icon: appStore,
    },
    {
      name: "RuStore",
      href: "https://apps.rustore.ru/app/ru.stmlabs.procheck.app",
      icon: ruStore,
    },
    {
      name: "NashStore",
      href: "https://nashstore.ru/",
      icon: nashStore,
    },
  ];
  return (
    <Dialog className={styles.dialog} overflow={true} title={"Скачать приложение"} isOpen={isOpenDialog} closeHandler={setIsOpenDialog}>
      <div className={styles.stores}>
        {stores.map((store) => {
          return (
            <a className={styles.link} href={store.href} target="_blank" key={store.name} rel="noreferrer">
              <img src={store.icon} width={150} alt={store.name} />
            </a>
          );
        })}
      </div>
    </Dialog>
  );
};
