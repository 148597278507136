import React from "react";
import styles from "./context-menu-table.module.scss";
import { Row } from "react-table";
import { Receipt } from "../../../types/receipts";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import classNames from "classnames";

interface Props {
  row: Row<Receipt>;
  categoryHandler: (id: string) => void;
  downloadHandler: (id: string) => void;
}

export function ContextMenuTable(props: Props) {
  const { row, downloadHandler, categoryHandler } = props;

  return (
    <div className={styles["context-menu-wrapper"]}>
      <div className={classNames(styles["context-menu"])}>
        <button
          onClick={() => {
            categoryHandler(row.original.receiptKey);
          }}
          className={styles.action}
        >
          <CategoryIcon /> Категория
        </button>
        <button
          onClick={() => {
            downloadHandler(row.original.receiptKey);
          }}
          className={styles.action}
        >
          <DownloadIcon /> Чек
        </button>
      </div>
    </div>
  );
}
