import { format, parseISO } from "date-fns";

enum Month {
  Dec = "дек.",
  Jan = "янв.",
  Feb = "фев.",
  Mar = "марта",
  Apr = "апр.",
  May = "мая",
  Jun = "июня",
  Jul = "июля",
  Aug = "авг.",
  Sep = "сен.",
  Oct = "окт.",
  Nov = "нояб.",
}

enum FullMonth {
  Dec = "декабря",
  Jan = "января",
  Feb = "февраля",
  Mar = "марта",
  Apr = "апреля",
  May = "мая",
  Jun = "июня",
  Jul = "июля",
  Aug = "августа",
  Sep = "сентября",
  Oct = "октября",
  Nov = "ноября",
}

export function customDateFormat(date: string, isFullMonth?: boolean) {
  const parsedDate = parseISO(date.split("+")[0]);
  const month = format(parsedDate, "MMM");
  return format(parsedDate, `d ${isFullMonth ? FullMonth[month as keyof typeof FullMonth] : Month[month as keyof typeof Month]}, kk:mm`);
}
