import { ReactComponent as ArrowRightSvg } from "../../../../assets/icons/arrow-right.svg";
import { ReactComponent as ArrowLeftSvg } from "../../../../assets/icons/arrow-left.svg";
import styles from "./pagination.module.scss";
import classNames from "classnames";

interface Props {
  page: number;
  pages: number;
  onChange(page: number): void;
  intervalWidth?: number;
}

export function Pagination({ page: pageFromProps, pages: pagesFromProps, intervalWidth = 2, onChange }: Props) {
  const pages = pagesFromProps < 1 ? 1 : pagesFromProps;
  let page = pageFromProps;
  if (page < 1) {
    page = 1;
  }
  if (page > pages) {
    page = pages;
  }

  const showLeftDots = page - (intervalWidth + 1) > 1;
  const showRightDots = page + intervalWidth + 1 < pages;

  return (
    <div className={styles["pagination-wrapper"]}>
      <div className={classNames(styles.button, { [styles.disabled]: page === 1 })} onClick={() => onChange(page - 1)}>
        <ArrowLeftSvg className={styles["arrow-right"]} />
      </div>
      <div className={classNames(styles.button, { [styles.selected]: page === 1 })} onClick={() => page !== 1 && onChange(1)}>
        1
      </div>
      {showLeftDots && (
        <div className={styles.button} onClick={() => onChange(page - (intervalWidth + 1))}>
          ...
        </div>
      )}
      {numbersArrayFromInterval(page, pages, [page - intervalWidth, page - 1]).map((pageNumber) => (
        <div className={styles.button} key={pageNumber} onClick={() => onChange(pageNumber)}>
          {pageNumber}
        </div>
      ))}
      {page !== 1 && page !== pages && <div className={classNames(styles.button, styles.selected)}>{page}</div>}
      {numbersArrayFromInterval(page, pages, [page + 1, page + intervalWidth]).map((pageNumber) => (
        <div className={styles.button} key={pageNumber} onClick={() => onChange(pageNumber)}>
          {pageNumber}
        </div>
      ))}
      {showRightDots && (
        <div className={styles.button} onClick={() => onChange(page + (intervalWidth + 1))}>
          ...
        </div>
      )}
      {pages > 1 && (
        <div className={classNames(styles.button, { [styles.selected]: page === pages })} onClick={() => onChange(pages)}>
          {pages}
        </div>
      )}
      <div
        className={classNames(styles.button, { [styles.disabled]: page === pages })}
        onClick={() => page !== pages && onChange(page + 1)}
      >
        <ArrowRightSvg className={styles["arrow-right"]} />
      </div>
    </div>
  );
}

function numbersArrayFromInterval(page: number, pages: number, [intervalStart, intervalEnd]: [number, number]): number[] {
  let start = intervalStart;
  let end = intervalEnd;
  if (start < 2) {
    start = 2;
  }
  if (end >= pages) {
    end = pages - 1;
  }
  if (start > end) {
    return [];
  }
  if (start === end) {
    return [start];
  }

  const numbers: number[] = [];
  for (let i = start; i <= end; i++) {
    numbers.push(i);
  }

  return numbers;
}
