import React from "react";
import styles from "./my-categories-modal.module.scss";
import { Dialog } from "../../ui-kit/Dialog";
import {
  useDeleteCategoryMutation,
  useNewCategoryMutation,
  useRenameCategoryMutation,
  useUserCategoriesMutation,
} from "../../../../services/api";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as NotFoundIcon } from "../../../../assets/images/not-yet.svg";
import { Button } from "../../ui-kit/Button";
import { EditCategoriesModal } from "./components/EditCategoriesModal";
import { DeleteCategoriesModal } from "./components/DeleteCategoriesModal";
import { Spinner } from "../../ui-kit";
import { Category } from "../../../../types/categories";
import { useNavigate } from "react-router-dom";

interface Props {
  isOpen: boolean;
  closeHandler: (state: boolean) => void;
}

export function MyCategoriesModal(props: Props) {
  const { isOpen, closeHandler } = props;

  const navigate = useNavigate();

  const [isOpenEditDialog, setIsOpenEditDialog] = React.useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = React.useState(false);
  const [currentCategoryKey, setCurrentCategoryKey] = React.useState<string | null>(null);
  const [currentCategoryName, setCurrentCategoryName] = React.useState<string>("");

  const [createNewCategory] = useNewCategoryMutation();
  const [renameCategory] = useRenameCategoryMutation();
  const [deleteCategory] = useDeleteCategoryMutation();
  const [fetchUserCategories, userCategoriesResp] = useUserCategoriesMutation();

  const userCategories = userCategoriesResp?.data?.result || [];

  React.useEffect(() => {
    mutateCategories();
  }, []);

  const mutateCategories = () => {
    fetchUserCategories({
      pagination: {
        paginationOffset: 0,
        paginationLimit: 50,
      },
      tapeCode: [5002],
    });
  };

  const confirmHandler = async (name: string, categoryKey: string | null | undefined) => {
    if (categoryKey) {
      await renameCategory({ name, categoryKey });
      await mutateCategories();
      return;
    }
    await createNewCategory({ name });
    await mutateCategories();
  };

  const deleteHandler = async (categoryKey: string | null) => {
    if (!categoryKey) return;
    await deleteCategory({ categoryKey });
    await mutateCategories();
    setCurrentCategoryKey(null);
    setCurrentCategoryName("");
  };

  const openEditDialog = (category: Category) => {
    setCurrentCategoryKey(category.key);
    setCurrentCategoryName(category.name);
    setIsOpenEditDialog(true);
  };

  const openDeleteDialog = (category: Category) => {
    setCurrentCategoryKey(category.key);
    setCurrentCategoryName(category.name);
    setIsOpenDeleteDialog(true);
  };

  const navigateHandler = (key: string) => {
    navigate(`/category/${key}`);
    closeHandler(false);
  };

  const actions = (
    <Button
      onClick={() => {
        setIsOpenEditDialog(true);
        setCurrentCategoryKey(null);
        setCurrentCategoryName("");
      }}
      className={styles["new-category"]}
    >
      Создать мою категорию
    </Button>
  );

  return (
    <Dialog actions={actions} className={styles.dialog} overflow={true} title={"Мои категории"} isOpen={isOpen} closeHandler={closeHandler}>
      {userCategoriesResp.isLoading ? (
        <Spinner verticalMargin={50} />
      ) : (
        <div>
          {userCategories.length > 0 ? (
            <div className={styles["categories-list"]}>
              {userCategories.map((category) => (
                <div key={category.key} className={styles["category-wrapper"]}>
                  <button onClick={() => navigateHandler(category.key)} className={styles.category}>
                    <img src={category.linkIcon} className={styles["category-icon"]} alt={"icon"} />
                    <div className={styles["category-name"]}>{category.name}</div>
                  </button>

                  <div className={styles["icons-wrapper"]}>
                    <button onClick={() => openEditDialog(category)}>
                      <EditIcon />
                    </button>

                    <button onClick={() => openDeleteDialog(category)}>
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.plug}>
              <NotFoundIcon />
              Ваших категорий пока нет
            </div>
          )}
        </div>
      )}

      <DeleteCategoriesModal
        isOpen={isOpenDeleteDialog}
        onClose={setIsOpenDeleteDialog}
        categoryKey={currentCategoryKey}
        onConfirm={deleteHandler}
        categoryName={currentCategoryName}
      />

      <EditCategoriesModal
        isOpen={isOpenEditDialog}
        onConfirm={confirmHandler}
        onClose={setIsOpenEditDialog}
        categoryKey={currentCategoryKey}
        categoryName={currentCategoryName}
      />
    </Dialog>
  );
}
