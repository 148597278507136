import React from "react";
import styles from "./form-footer.module.scss";

export function FormFooter() {
  return (
    <div className={styles["form-footer"]}>
      Вы можете запросить удаление аккаунта и связанных с ним данных написав письмо на support@pro-check.ru и указав свой номер телефона.
      <p>STM Labs © 2023. Все права защищены.</p>
    </div>
  );
}
