import React from "react";
import styles from "./dialog.module.scss";
import { Dialog as DialogComponent } from "@reach/dialog";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import classNames from "classnames";
import { Divider } from "../Divider";

interface Props {
  isOpen: boolean;
  closeHandler: (state: boolean) => void;
  overflow?: boolean;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  title?: string;
  className?: string;
}

export function Dialog(props: Props) {
  const { isOpen, title, closeHandler, children, overflow = false, className: externalClassName, actions } = props;

  return (
    <DialogComponent
      aria-label={"content-dialog"}
      isOpen={isOpen}
      className={classNames(styles.dialog, externalClassName, { [styles["dialog-scroll"]]: overflow })}
    >
      <div>
        {title && <div className={styles.title}>{title}</div>}
        <button className={styles["close-btn"]} onClick={() => closeHandler(false)}>
          <CloseIcon />
        </button>
      </div>

      <div className={styles["content-wrapper"]}>
        <div className={styles["content"]}>{children}</div>
      </div>

      {actions && (
        <div>
          <Divider />
          {actions}
        </div>
      )}
    </DialogComponent>
  );
}
