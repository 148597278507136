import React from "react";
import styles from "./login-form.module.scss";
import { Button, Input } from "@flexiflow-ui-kit/common";

interface Props {
  phone: string;
  setPhone: (event: React.ChangeEvent<HTMLInputElement>) => void;
  code: string;
  setCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  submitForm: (event: React.FormEvent<HTMLFormElement>) => void;
  isLoginSuccessInitialized: boolean;
}

export function LoginForm(props: Props) {
  const { phone, setPhone, code, setCode, submitForm, isLoginSuccessInitialized } = props;

  return (
    <form className={styles["login-form"]} onSubmit={submitForm}>
      <div className={styles.title}>Вход</div>

      {isLoginSuccessInitialized ? (
        <React.Fragment>
          <div className={styles["secondary-title"]}>
            Мы отправили СМС с кодом на номер <br />
            {phone}
          </div>
          <Input label="Код из SMS" id="code" name="code" value={code} type="number" onChange={setCode} />
        </React.Fragment>
      ) : (
        <Input label="Телефон" id="phoneNumber" name="phoneNumber" value={phone} onChange={setPhone} />
      )}

      <Button type="submit">Войти</Button>
    </form>
  );
}
