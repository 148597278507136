import React from "react";
import { ReactComponent as Logo } from "../../../assets/logos/pro-check.svg";
import { ReactComponent as Exit } from "../../../assets/icons/exit.svg";
import { ReactComponent as Files } from "../../../assets/icons/files.svg";
import styles from "./sidebar.module.scss";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { ConfirmationModal } from "../ConfirmationModal";
import { useLogOutMutation } from "../../../services/api";
import { useCookies } from "react-cookie";
import { Tokens } from "../../../utils";

export function Sidebar() {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  const [logOut] = useLogOutMutation();
  const [, , removeCookie] = useCookies([Tokens.accessToken, Tokens.refreshToken]);

  const sidebarClasses = classNames(styles.sidebar, { [styles["is-expanded"]]: isExpanded });
  const titleNavItemClasses = classNames(styles["nav-item"], styles["nav-item-title"], {
    [styles["nav-item-expanded"]]: isExpanded,
  });
  const navItemClasses = classNames(styles["nav-item"], {
    [styles["nav-item-expanded"]]: isExpanded,
  });

  const handleLogout = () => {
    logOut().finally(() => {
      removeCookie(Tokens.refreshToken);
      removeCookie(Tokens.accessToken);
      setIsOpen(false);
    });
  };

  const onLogoutOpen = () => {
    setIsOpen(true);
    setIsExpanded(false);
  };
  return (
    <div className={sidebarClasses} onMouseLeave={() => setIsExpanded(false)}>
      <div onMouseEnter={() => setIsExpanded(true)} className={styles["nav-items"]}>
        <NavLink to="/receipts" className={titleNavItemClasses}>
          <Logo className={styles.logo} /> {isExpanded && <div className={styles["pro-check-title"]}>ПроЧек</div>}
        </NavLink>

        <NavLink to="/receipts" className={navItemClasses}>
          <Files className={styles["nav-icon"]} /> {isExpanded && <div>Список чеков</div>}
        </NavLink>
      </div>
      <div onMouseEnter={() => setIsExpanded(true)} className={styles["nav-items"]}>
        <div className={navItemClasses} onClick={onLogoutOpen}>
          <Exit className={styles["nav-icon"]} /> {isExpanded && <div>Выход</div>}
        </div>
      </div>

      <ConfirmationModal
        isOpen={isOpen}
        title="Выйти из системы?"
        confirmationButtonText="Выйти"
        close={() => setIsOpen(false)}
        onConfirm={handleLogout}
      />
    </div>
  );
}
