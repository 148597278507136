import React from "react";
import styles from "./header.module.scss";
import { CategoriesActions } from "../CategoriesActions";
import classNames from "classnames";
import { Divider } from "../ui-kit/Divider";
import { ReactComponent as ArrowBackIcon } from "../../../assets/icons/arrow-left.svg";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  subheader?: string;
  divider?: boolean;
  icon?: string;
  className?: string;
  back?: boolean;
}

export function Header(props: Props) {
  const { title, subheader, divider = true, icon, className: externalClassName, back = false } = props;

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className={classNames(styles["header-wrapper"], externalClassName)}>
        <div className={styles.header}>
          <div className={styles["title-wrapper"]}>
            {back && (
              <button onClick={() => navigate(-1)} className={styles.back}>
                <ArrowBackIcon />
              </button>
            )}
            {icon && <img src={icon} alt={"Category"} className={styles.icon} />}
            <div>
              {title}
              <div>{subheader && <div className={styles.subheader}>{subheader}</div>}</div>
            </div>
          </div>
        </div>

        <div className={styles.actions}>
          <CategoriesActions />
        </div>
      </div>

      {divider && <Divider className={styles.divider} />}
    </React.Fragment>
  );
}
