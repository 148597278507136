import React from "react";
import styles from "./divider.module.scss";
import classNames from "classnames";

interface Props {
  className?: string;
}

export function Divider({ className: externalClassName }: Props) {
  return <div className={classNames(styles.divider, externalClassName)}></div>;
}
