import { CommonResponseModel } from "./common-response-model";

export enum Columns {
  DateTime = "dateTime",
  TotalSum = "totalSum",
  IconUrl = "iconUrl",
  OriginalName = "originalName",
  OriginalDate = "originalDate",
}

export interface Receipt {
  typeReceipt: string;
  categoriesList: string[];
  dateTime: number;
  dateTimeAdd: number;
  defaultCategoryKey: null;
  hasWarranty: boolean;
  icon: string;
  isArchived: boolean;
  isArtificial: boolean;
  isEquality: boolean;
  isJoined: boolean;
  isNew: boolean;
  isOriginalName: boolean;
  isOwner: boolean;
  isSave: boolean;
  isShared: boolean;
  isVirtual: boolean;
  items: [
    {
      itemId: string;
      sum: number;
      quantity: string;
      name: string;
      price: number;
      propertiesItem: string;
      categoryKey: null;
      defaultCategoryKey: null;
      groupsList: [];
      endWarrantyDate: string;
      averagePrice: {
        excellent: {
          min: number;
          max: number;
        };
        good: {
          min: number;
          max: number;
        };
        bad: {
          min: number;
          max: number;
        };
      };
      tagCode: number;
    }
  ];
  joinedAmount: number;
  joinedItems: [];
  joinedRecipientList: [];
  originalDate: string;
  originalName: string;
  ownerPhone: string;
  receiptKey: string;
  receiptWay: number;
  recipientList: [];
  retailPlace: string;
  retailPlaceAddress: string;
  senderPhone: string;
  shortId: string;
  statusList: {
    shared: [];
    joined: [];
  };
  totalSum: number;
  user: string;
  userInn: string;
  userPhone: string;
  comment: null;
  iconUrl: string;
}

export type ReceiptsList = {
  total: number;
} & CommonResponseModel<Receipt[]>;

export interface ReceiptsListReq {
  query: string;
  pagination: {
    paginationOffset: number;
    paginationLimit: number;
  };
  tapeCode: (2031 | 2032 | 2033 | 2034)[];
  filter: {
    isNew?: true;
    isArtificial?: true;
    hasWarranty?: true;
    periodDate?: {
      from: number;
      to: number;
    };
  };
  sort: {
    field?: string;
    orderBy?: -1 | 1;
  };
}

export interface CategoryReceipts {
  receiptsList: Receipt[];
  isEmpty: boolean;
  amount: number;
  count: number;
  amountPerDate: number;
  countPerDate: number;
  amountCurrentMonth: number;
  countCurrentMonth: number;
}

export interface CategoryReceiptsReq {
  categoryKey: string;
  pagination: {
    paginationOffset: number;
    paginationLimit: number;
  };
  periodDate?: {
    beginDate: number;
    endDate: number;
  };
}

export interface ReceiptKeyReq {
  receiptKey: string;
}

export interface ChangeCategoriesReceiptReq {
  receiptKey: string;
  categoriesList: string[];
}
