import React from "react";
import styles from "./edit-categories-modal.module.scss";
import { Dialog } from "../../../../ui-kit/Dialog";
import { Button } from "../../../../ui-kit/Button";
import { Input } from "@flexiflow-ui-kit/common";

interface Props {
  isOpen: boolean;
  onConfirm: (name: string, categoryKey?: string | null) => Promise<void>;
  onClose: (state: boolean) => void;
  categoryKey: string | null;
  categoryName: string;
}

export function EditCategoriesModal(props: Props) {
  const { isOpen, onConfirm, onClose, categoryKey, categoryName } = props;

  const [name, setName] = React.useState("");

  const title = categoryKey ? "Редактирование категории" : "Новая категория";
  const actionName = categoryKey ? "Сохранить" : "Создать";

  React.useEffect(() => {
    setName(categoryName);
  }, [categoryName]);

  const closeHandler = () => {
    onClose(false);
  };

  const confirmHandler = () => {
    if (!name) return;
    onConfirm(name, categoryKey);
    closeHandler();
  };

  return (
    <Dialog className={styles.dialog} title={title} isOpen={isOpen} closeHandler={closeHandler}>
      <Input
        className={styles.input}
        label={"Название"}
        value={name}
        onChange={(event) => {
          if (name.length > 80) return;
          setName(event.target.value);
        }}
        type="text"
      />

      <div className={styles["action-wrapper"]}>
        <Button className={styles.confirm} onClick={confirmHandler}>
          {actionName}
        </Button>
        <Button onClick={closeHandler}>Отмена</Button>
      </div>
    </Dialog>
  );
}
