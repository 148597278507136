import React from "react";
import styles from "./delete-categories-modal.module.scss";
import { Dialog } from "../../../../ui-kit/Dialog";
import { Button } from "../../../../ui-kit/Button";

interface Props {
  categoryKey: string | null;
  categoryName: string | null;
  onConfirm: (categoryKey: string | null) => Promise<void>;
  isOpen: boolean;
  onClose: (state: boolean) => void;
}

export function DeleteCategoriesModal(props: Props) {
  const { categoryKey, onConfirm, isOpen, onClose, categoryName } = props;

  const title = `Удалить категорию "${categoryName}"`;

  const conformHandler = () => {
    onConfirm(categoryKey);
    onClose(false);
  };

  return (
    <Dialog className={styles.dialog} title={title} isOpen={isOpen} closeHandler={() => onClose(false)}>
      <div className={styles["action-wrapper"]}>
        <Button className={styles.confirm} onClick={conformHandler}>
          Удалить
        </Button>
        <Button onClick={() => onClose(false)}>Отмена</Button>
      </div>
    </Dialog>
  );
}
