import React from "react";
import styles from "./receipt-page.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDownloadReceiptMutation, useGetCategoryMutation, useReceiptByKeyMutation } from "../../services/api";
import { Header } from "../../components/shared/Header";
import { FullScreenSpinner } from "../../components/shared/FullScreenSpinner";
import { formatCurrency } from "../../utils/formatCurrency";
import { Divider } from "../../components/shared/ui-kit/Divider";
import { Button } from "../../components/shared/ui-kit/Button";
import { customDateFormat } from "../../helpers/date-helpers";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { EditReceiptCategoriesModal } from "../../components/shared/EditReceiptCategoryModal";

export function ReceiptPage() {
  const params = useParams();
  const navigate = useNavigate();

  const [isOpenEditReceiptCategory, setIsOpenEditReceiptCategory] = React.useState(false);

  const [fetchReceipt, receiptResp] = useReceiptByKeyMutation();
  const [downloadReceipt] = useDownloadReceiptMutation();
  const [fetchCategory, categoryResp] = useGetCategoryMutation();

  const receipt = receiptResp.data?.result;
  const categoriesList = receiptResp.data?.result.categoriesList;

  React.useEffect(() => {
    fetchReceipt({ receiptKey: params.id! });
  }, [params.id]);

  React.useEffect(() => {
    if (categoriesList && categoriesList.length > 0) {
      fetchCategory({ categoryKey: categoriesList[0] });
    }
  }, [categoriesList]);

  React.useEffect(() => {
    if (!receiptResp.isUninitialized && !receiptResp.isLoading) {
      if (!receipt) navigate("/receipts");
    }
  }, [receiptResp]);

  return (
    <div className={styles.page}>
      <EditReceiptCategoriesModal
        currentReceiptKey={params.id!}
        isOpen={isOpenEditReceiptCategory}
        closeHandler={setIsOpenEditReceiptCategory}
        refetchReceipt={() => fetchReceipt({ receiptKey: params.id! })}
      />

      {receiptResp.isLoading || categoryResp.isLoading || !receipt ? (
        <FullScreenSpinner />
      ) : (
        <>
          <div className={styles["header-wrapper"]}>
            <Header back={true} className={styles.header} title={"Детализация чека"} />
          </div>

          <div className={styles["content-wrapper"]}>
            <div>
              <div className={styles["info-wrapper"]}>
                <img className={styles.icon} src={receipt.iconUrl} alt={"receipt"} />
                <div className={styles.name}>{receipt.originalName}</div>
                <div className={styles.price}>{formatCurrency(receipt.totalSum / 100)}</div>
              </div>

              <div className={styles["items-wrapper"]}>
                <div className={styles.subtitle}>Позиции</div>
                {receipt.items.map((item) => (
                  <React.Fragment key={item.itemId}>
                    <div className={styles["item-wrapper"]}>
                      <div className={styles["item-name"]}>{item.name}</div>

                      <div className={styles["price-wrapper"]}>
                        <div>{formatCurrency(item.sum / 100)}</div>

                        <div className={styles["price-item"]} key={item.itemId}>
                          {item.quantity}
                          <span> x </span>
                          {formatCurrency(item.price / 100)}
                        </div>
                      </div>
                    </div>

                    <Divider className={styles.divider} />
                  </React.Fragment>
                ))}
              </div>
            </div>

            <div>
              <div className={styles.categories}>
                <div className={styles.subtitle}>Категории</div>
                {categoryResp.data?.result.name && receipt.categoriesList.length > 0 ? (
                  <div className={styles["categories-amount"]}>
                    {categoryResp.data?.result.name}
                    {receipt.categoriesList.length > 1 ? ` и еще ${receipt.categoriesList.length - 1}` : null}
                    <div onClick={() => setIsOpenEditReceiptCategory(true)} className={styles["edit-icon"]}>
                      <EditIcon />
                    </div>
                  </div>
                ) : (
                  <div className={styles["categories-amount"]}>
                    Выбрать категорию
                    <div onClick={() => setIsOpenEditReceiptCategory(true)} className={styles["edit-icon"]}>
                      <EditIcon />
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.details}>
                <div className={styles.subtitle}>Детали покупки</div>
                <div>{customDateFormat(receipt.originalDate, true)}</div>
                <Button onClick={() => downloadReceipt(params.id!)} className={styles["btn-download"]}>
                  <div className={styles["icon-wrapper"]}>
                    <DownloadIcon />
                  </div>
                  Фискальный чек
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
