import React from "react";
import styles from "./table.module.scss";
import { Column, Row, useTable } from "react-table";
import classNames from "classnames";
import { ReactComponent as ArrowDown } from "../../../../assets/icons/arrow-down.svg";

interface CustomColumnsProps {
  isSortable?: boolean;
  columnName?: string;
  textAlign?: "center" | "right" | "left";
}

export type CustomColumn<T extends object = {}> = Column<T> & CustomColumnsProps;

interface Props<T extends object> {
  columns: CustomColumn<T>[];
  data: T[];
  sort?: (column: string) => void;
  sortAsc?: boolean;
  activeSortColumn?: string;
  backgroundHeader?: string | undefined;
  colorTextHeader?: string | undefined;
  renderExtraColumn?: (row: Row<T>) => JSX.Element;
  onClickRow?: (row?: Row<T>) => void;
}

export function Table<T extends object>(props: Props<T>) {
  const { columns, data = [], activeSortColumn, sort, sortAsc, colorTextHeader, backgroundHeader, renderExtraColumn, onClickRow } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <table {...getTableProps()} className={styles.table}>
      <thead>
        {headerGroups.map((headerGroup: any) => (
          <tr
            className={styles.header}
            style={{ backgroundColor: backgroundHeader, color: colorTextHeader }}
            {...headerGroup.getHeaderGroupProps()}
            key={headerGroup.getHeaderGroupProps().key}
          >
            {headerGroup.headers.map((column: any) => {
              const sortDownClass = classNames(styles["sort-arrow-down"], {
                [styles["sort-arrow-down_reverse"]]: column.columnName === activeSortColumn && !sortAsc,
              });

              return (
                <th
                  width={column.width}
                  style={{ minWidth: column.minWidth }}
                  className={classNames(styles.cell, styles["header-cell"], styles.nowrap, styles[`cell_${column.textAlign}`], {
                    [styles["cell-sort"]]: !!sort && column.isSortable,
                  })}
                  {...column.getHeaderProps()}
                  onClick={() => (sort && column.columnName ? sort(column.columnName) : null)}
                  key={column.getHeaderProps().key}
                >
                  <div className={classNames(styles["header-wrapper"], styles[`header-wrapper_${column.textAlign}`])}>
                    {column.render("Header")}
                    {sort && column.columnName === activeSortColumn ? (
                      <div className={styles["sort-icon-wrapper"]}>
                        <ArrowDown className={sortDownClass} />
                      </div>
                    ) : null}
                  </div>
                </th>
              );
            })}
            {renderExtraColumn && <th className={styles["extra-column"]} />}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row);
          return (
            <tr
              className={classNames(styles.row, { [styles["with-click-handler"]]: onClickRow })}
              {...row.getRowProps()}
              key={row.getRowProps().key}
            >
              {row.cells.map((cell: any) => {
                const cellClasses = classNames(styles.cell, styles[`cell_${cell.column.textAlign}`]);

                return (
                  <td
                    className={cellClasses}
                    {...cell.getCellProps()}
                    key={cell.getCellProps().key}
                    onClick={() => (onClickRow ? onClickRow(row) : null)}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
              {renderExtraColumn && <td className={classNames(styles["extra-column"], styles.cell)}>{renderExtraColumn(row)}</td>}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
