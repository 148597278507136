import React from "react";
import styles from "./action-button.module.scss";
import classNames from "classnames";

interface Props {
  color?: "blue" | "purple" | "pink" | "orange";
  icon?: React.ReactNode;
  children?: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function ActionButton(props: Props) {
  const { children, color = "blue", icon, className: externalClassName, onClick } = props;

  return (
    <button onClick={onClick} className={classNames(styles.button, styles[`button-${color}`], externalClassName)}>
      <div className={styles.icon}>{icon}</div>
      {children}
      <div className={styles.backdrop}></div>
    </button>
  );
}
