import React from "react";
import styles from "./edit-receipt-categories-modal.module.scss";
import { useChangeCategoriesMutation, useReceiptByKeyMutation, useUserCategoriesMutation } from "../../../services/api";
import { Dialog } from "../ui-kit/Dialog";
import { Category } from "../../../types/categories";
import { Spinner } from "../ui-kit";
import { CategoryList } from "./CategoryList";
import { Button } from "../ui-kit/Button";

interface Props {
  isOpen: boolean;
  closeHandler: (state: boolean) => void;
  currentReceiptKey: string;
  refetchReceipt: () => void;
}

export function EditReceiptCategoriesModal(props: Props) {
  const { isOpen, closeHandler, currentReceiptKey, refetchReceipt } = props;

  const [categories, setCategories] = React.useState<Category[]>([]);
  const [myCategories, setMyCategories] = React.useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>([]);

  const [fetchUserCategories, userCategoriesResp] = useUserCategoriesMutation();
  const [changeReceiptCategories] = useChangeCategoriesMutation();
  const [fetchReceipt, receiptData] = useReceiptByKeyMutation();

  React.useEffect(() => {
    if (!isOpen) return;
    fetchReceipt({ receiptKey: currentReceiptKey });
  }, [isOpen]);

  React.useEffect(() => {
    if (!isOpen) return;
    setSelectedCategories(receiptData.data?.result.categoriesList || []);
  }, [receiptData.data]);

  React.useEffect(() => {
    mutateCategories(5000).then((data) => {
      // @ts-ignore
      setCategories(data?.data?.result);
    });
    mutateCategories(5002).then((data) => {
      // @ts-ignore
      setMyCategories(data?.data?.result);
    });
  }, [isOpen]);

  const mutateCategories = (tapeCode: 5000 | 5002) => {
    return fetchUserCategories({
      pagination: {
        paginationOffset: 0,
        paginationLimit: 50,
      },
      tapeCode: [tapeCode],
    });
  };

  const onChangeCategories = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    if (event.target.checked) {
      setSelectedCategories([...selectedCategories, key]);
      return;
    }
    setSelectedCategories(selectedCategories.filter((i) => i !== key));
  };

  return (
    <Dialog
      actions={
        <Button
          className={styles.action}
          onClick={async () => {
            closeHandler(false);
            await changeReceiptCategories({ receiptKey: currentReceiptKey, categoriesList: selectedCategories });
            await refetchReceipt();
          }}
        >
          Сохранить
        </Button>
      }
      className={styles.dialog}
      overflow={true}
      title={"Выберите категорию"}
      isOpen={isOpen}
      closeHandler={closeHandler}
    >
      {userCategoriesResp.isLoading || receiptData.isLoading ? (
        <Spinner verticalMargin={50} />
      ) : (
        <>
          <div className={styles.label}>Мои</div>
          <CategoryList selectedCategories={selectedCategories} categoryList={myCategories} onChangeCategories={onChangeCategories} />

          <div className={styles.label}>Остальные</div>
          <CategoryList selectedCategories={selectedCategories} categoryList={categories} onChangeCategories={onChangeCategories} />
        </>
      )}
    </Dialog>
  );
}
