import React, { ReactElement } from "react";
import styles from "./spinner.module.scss";

interface SpinnerProps {
  isOpen?: boolean;
  diameter?: number;
  verticalMargin?: number;
  uniqName?: string;
}

export function Spinner({ isOpen = true, diameter = 80, verticalMargin = 0, uniqName = "Не указано" }: SpinnerProps): ReactElement {
  const calcHeight = (): string => {
    return verticalMargin ? `${verticalMargin * 2 + diameter}px` : "98vh";
  };

  return (
    <React.Fragment>
      {isOpen && (
        <div className={styles["spinner-wrapper"]} style={{ height: calcHeight() }} data-uniq-name={uniqName}>
          <div className={styles.spinner} style={{ height: `${diameter}px`, width: `${diameter}px` }} />
        </div>
      )}
    </React.Fragment>
  );
}
