import React from "react";
import styles from "./confirmation-modal.module.scss";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { Button } from "@flexiflow-ui-kit/common";

interface DialogProps {
  title: string;
  confirmationButtonText: string;
  isOpen: boolean;
  close: () => void;
  onConfirm: () => void;
}

export function ConfirmationModal(props: DialogProps) {
  const { title, isOpen, close, confirmationButtonText, onConfirm } = props;
  return (
    <DialogOverlay className={styles.overlay} isOpen={isOpen} onDismiss={close} allowPinchZoom>
      <DialogContent className={styles["content-wrapper"]} aria-label="modal">
        <div className={styles["header-wrapper"]}>
          <div className={styles["header-title"]}>{title}</div>
          <button className={styles["close-button"]} onClick={close}>
            <CloseIcon className={styles["close-icon"]} />
          </button>
        </div>
        <div className={styles.actions}>
          <Button onClick={onConfirm}>{confirmationButtonText}</Button>
          <Button onClick={close}>Отмена</Button>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
}
