import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";

interface Props {
  children: ReactNode;
}

export function RequireAuth({ children }: Props) {
  const isAuth = useAppSelector((state) => state.auth.isAuth);

  if (!isAuth) {
    return (
      <Navigate
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
}
