import React from "react";
import styles from "./email-form.module.scss";
import { Button, Input } from "@flexiflow-ui-kit/common";

interface Props {
  email: string;
  setEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  submitForm: (event: React.FormEvent<HTMLFormElement>) => void;
  error: string | undefined;
}

export function EmailForm(props: Props) {
  const { email, setEmail, submitForm, error } = props;

  return (
    <form className={styles.form} onSubmit={submitForm}>
      <div className={styles.title}>Укажите Email-адрес</div>
      <div className={styles.description}>Это нужно, чтобы получать больше электронных чеков</div>

      <Input value={email} onChange={setEmail} type={"email"} placeholder={"Введите email"} />

      {error && <div className={styles.error}>{error}</div>}

      <Button type={"submit"}>Продолжить</Button>
    </form>
  );
}
