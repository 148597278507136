import React from "react";
import { ActionButton } from "../ui-kit/ActionButton";
import { ReactComponent as CategoriesIcon } from "../../../assets/icons/categories.svg";
import { ReactComponent as MyCategoriesIcon } from "../../../assets/icons/my-categories.svg";
import { MyCategoriesModal } from "./MyCategoriesModal";
import { CategoriesModal } from "./CategoriesModal";

export function CategoriesActions() {
  const [isOpenCategories, setIsOpenCategories] = React.useState(false);
  const [isOpenMyCategories, setIsOpenMyCategories] = React.useState(false);

  return (
    <>
      <ActionButton onClick={() => setIsOpenCategories(true)} icon={<CategoriesIcon />}>
        Категории
      </ActionButton>
      <ActionButton onClick={() => setIsOpenMyCategories(true)} icon={<MyCategoriesIcon />} color={"purple"}>
        Мои категории
      </ActionButton>

      <CategoriesModal isOpen={isOpenCategories} closeHandler={setIsOpenCategories} />
      <MyCategoriesModal isOpen={isOpenMyCategories} closeHandler={setIsOpenMyCategories} />
    </>
  );
}
