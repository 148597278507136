import React from "react";
import styles from "./login.module.scss";
import { ReactComponent as Logo } from "../../assets/logos/pro-check-logo.svg";
import { FormFooter } from "./components/FormFooter";
import { LoginForm } from "./components/LoginForm";
import { useNavigate } from "react-router-dom";
import { useBindUserEmailsMutation, useInitLoginMutation, useValidateLoginMutation } from "../../services/api";
import { Tokens } from "../../utils";
import { addHours } from "date-fns";
import { useCookies } from "react-cookie";
import { FullScreenSpinner } from "../../components/shared/FullScreenSpinner";
import { useAppSelector } from "../../hooks";
import { EmailForm } from "./components/EmailForm";
import { StoreNotification } from "../../components/shared/StoreNotification";

export function Login() {
  const [phone, setPhone] = React.useState("+7");
  const [code, setCode] = React.useState("");
  const [isConnected, setIsConnected] = React.useState<boolean | null>(null);
  const [accessToken, setAccessToken] = React.useState("");
  const [refreshToken, setRefreshToken] = React.useState("");
  const [email, setEmail] = React.useState("");

  const isAuth = useAppSelector((state) => state.auth.isAuth);

  const navigate = useNavigate();
  const [, setCookie] = useCookies([Tokens.accessToken, Tokens.refreshToken]);

  const [validateLogin, validateResp] = useValidateLoginMutation();
  const [initLogin, loginResp] = useInitLoginMutation();
  const [bindEmailMutation, bindEmailResp] = useBindUserEmailsMutation();

  const isLoading = validateResp.isLoading;
  const regExpOnlyNum = /\D+/gm;

  React.useEffect(() => {
    if (isAuth) navigate("/receipts");
  }, [isAuth]);

  function submitPhone(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (loginResp.isUninitialized || loginResp.isError) {
      initLogin({ phone: regExpOnlyNum[Symbol.replace](phone, "") });
      return;
    }

    // @ts-ignore
    validateLogin({ phone: regExpOnlyNum[Symbol.replace](phone, ""), code }).then(({ data }) => {
      if (data.result.isConnected) {
        setCookie(Tokens.accessToken, data.result.accessToken, { expires: addHours(new Date(), 24) });
        setCookie(Tokens.refreshToken, data.result.refreshToken, { expires: addHours(new Date(), 1440) });

        return;
      }

      setAccessToken(data.result.accessToken);
      setRefreshToken(data.result.refreshToken);
      setIsConnected(data.result.isConnected);
    });
  }

  function submitEmail(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!accessToken) return;

    bindEmailMutation({ emails: [email], token: accessToken })
      .unwrap()
      .then(() => {
        setCookie(Tokens.accessToken, accessToken, { expires: addHours(new Date(), 24) });
        setCookie(Tokens.refreshToken, refreshToken, { expires: addHours(new Date(), 1440) });
      });
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Logo />

        {isConnected === null && (
          <LoginForm
            isLoginSuccessInitialized={loginResp.isSuccess}
            phone={phone}
            setPhone={(event) => {
              const regExpOlyPlusAndNum = /[^(\s\d+)]+/;
              setPhone(regExpOlyPlusAndNum[Symbol.replace](event.target.value, ""));
            }}
            code={code}
            setCode={(event) => setCode(event.target.value)}
            submitForm={submitPhone}
          />
        )}

        {!isConnected && isConnected !== null ? (
          <EmailForm
            email={email}
            setEmail={(event) => setEmail(event.target.value)}
            submitForm={submitEmail}
            //@ts-ignore
            error={bindEmailResp?.error?.data?.msg}
          />
        ) : null}
        <FormFooter />
      </div>

      <StoreNotification />

      {isLoading && <FullScreenSpinner />}
    </div>
  );
}
