import React from "react";
import styles from "./category-page.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDownloadReceiptMutation, useGetCategoryMutation, useGetCategoryReceiptsMutation } from "../../services/api";
import { Header } from "../../components/shared/Header";
import { Table } from "../../components/shared/ui-kit";
import { CustomColumn } from "../../components/shared/ui-kit/Table/Table";
import { customDateFormat } from "../../helpers/date-helpers";
import { Columns, Receipt } from "../../types/receipts";
import { FullScreenSpinner } from "../../components/shared/FullScreenSpinner";
import { ReactComponent as NotFoundIcon } from "../../assets/images/not-found.svg";
import { Pagination } from "../../components/shared/ui-kit/Pagination";
import { ContextMenuTable } from "../../components/shared/ContextMenuTable";
import { EditReceiptCategoriesModal } from "../../components/shared/EditReceiptCategoryModal";

const COLUMNS: CustomColumn<Receipt>[] = [
  {
    accessor: Columns.IconUrl,
    Cell: ({ value }: { value: string }) => (
      <div className={styles["icon-wrapper"]}>
        <img src={value} className={styles.icon} alt="" />
      </div>
    ),
    width: "5%",
    minWidth: 76,
  },
  {
    Header: "Наименование магазина",
    accessor: Columns.OriginalName,
    Cell: ({ value }) => value,
  },
  {
    Header: () => <div className={styles["cell-sum"]}>Сумма покупки</div>,
    accessor: Columns.TotalSum,
    Cell: ({ value }: { value: string }) => <div className={styles["cell-sum"]}>{`${(+value / 100).toFixed(2)} ₽`}</div>,
    textAlign: "right",
    columnName: Columns.TotalSum,
    width: "10%",
    minWidth: 150,
  },
  {
    Header: "Дата",
    accessor: Columns.OriginalDate,
    Cell: ({ value }: { value: string }) => <div className={styles["cell-date"]}>{customDateFormat(value)}</div>,
    columnName: Columns.OriginalDate,
    width: "10%",
    minWidth: 150,
  },
];

export function CategoryPage() {
  const columns = React.useMemo(() => COLUMNS, []);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [isOpenEditReceiptCategory, setIsOpenEditReceiptCategory] = React.useState(false);
  const [currentReceiptKey, setCurrentReceiptKey] = React.useState("");

  const [downloadReceiptMutation, downloadReceiptResp] = useDownloadReceiptMutation();
  const [fetchCategory, categoryResp] = useGetCategoryMutation();
  const [fetchCategoryReceipts, categoryReceiptsResp] = useGetCategoryReceiptsMutation();

  const params = useParams();
  const navigate = useNavigate();

  const categoryData = categoryResp.data?.result;
  const receiptsList = categoryReceiptsResp.data?.result.receiptsList || [];

  const isLoading = categoryReceiptsResp.isLoading || categoryResp.isLoading || downloadReceiptResp.isLoading;

  const total = categoryReceiptsResp?.data?.result.count ?? 0;

  const paginationLimit = 20;

  const amountPage = Math.round(total / paginationLimit);

  React.useEffect(() => {
    mutateReceipts();
  }, [currentPage]);

  React.useEffect(() => {
    fetchCategory({ categoryKey: params.id! });
    mutateReceipts();
  }, [params.id]);

  React.useEffect(() => {
    if (categoryResp.isError) navigate("/");
  }, [categoryResp]);

  const mutateReceipts = () => {
    fetchCategoryReceipts({
      categoryKey: params.id!,
      pagination: {
        paginationOffset: currentPage * paginationLimit - paginationLimit,
        paginationLimit: paginationLimit,
      },
    });
  };

  const downloadReceipt = (id: string) => {
    downloadReceiptMutation(id);
  };

  const editReceiptCategory = (id: string) => {
    setIsOpenEditReceiptCategory(true);
    setCurrentReceiptKey(id);
  };

  return (
    <div className={styles.page}>
      <Header
        className={styles.header}
        title={categoryData?.name || ""}
        icon={`https://pro-chek.ru/static/images/${categoryData?.linkIcon}` || ""}
        subheader={!isLoading ? `Всего: ${total}` : undefined}
      />
      {isLoading && <FullScreenSpinner />}
      {categoryData && (
        <div className={styles["table-wrapper"]}>
          <div className={styles.table}>
            {receiptsList.length === 0 && !isLoading ? (
              <div className={styles.plug}>
                <NotFoundIcon className={styles["plug-image"]} />
                <div className={styles["plug-text"]}>Чеков пока нет</div>
              </div>
            ) : (
              <Table
                backgroundHeader={categoryData?.colorBackground}
                colorTextHeader={categoryData?.colorText}
                columns={columns}
                data={receiptsList}
                renderExtraColumn={(row) => (
                  <ContextMenuTable categoryHandler={editReceiptCategory} downloadHandler={downloadReceipt} row={row} />
                )}
                onClickRow={(row) => navigate(`/receipt/${row?.original.receiptKey}`)}
              />
            )}
          </div>
        </div>
      )}
      <div className={styles["pagination-wrapper"]}>
        <Pagination page={currentPage} pages={amountPage} onChange={(page) => setCurrentPage(page)} />
      </div>

      <EditReceiptCategoriesModal
        currentReceiptKey={currentReceiptKey}
        isOpen={isOpenEditReceiptCategory}
        closeHandler={setIsOpenEditReceiptCategory}
        refetchReceipt={mutateReceipts}
      />
    </div>
  );
}
