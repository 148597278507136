import React from "react";
import styles from "./app.module.scss";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Login } from "./pages/Login";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "./components/shared/ErrorPage";
import { useAppDispatch } from "./hooks";
import { setAuth } from "./store/slices/auth";
import { RequireAuth } from "./components/shared/RequireAuth";
import { Sidebar } from "./components/shared/Sidebar";
import { MainContent } from "./components/shared/MainContent";
import { CategoryPage } from "./pages/CategoryPage";
import { ReceiptPage } from "./pages/ReceiptPage";
import { Tokens } from "./utils";
import { useCookies } from "react-cookie";
import { FullScreenSpinner } from "./components/shared/FullScreenSpinner";

const ReceiptsPage = React.lazy(() => import("./pages/ReceiptsPage"));

function App() {
  const [initialLoading, setInitialLoading] = React.useState(true);

  const [cookies] = useCookies([Tokens.accessToken, Tokens.refreshToken]);

  const location = useLocation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setAuth(cookies.accessToken || cookies.refreshToken));
    setInitialLoading(false);
  }, [cookies.accessToken]);

  return initialLoading ? (
    <FullScreenSpinner />
  ) : (
    <div className={styles.root}>
      <ErrorBoundary FallbackComponent={ErrorPage} resetKeys={[location.pathname]}>
        <Routes>
          <Route path={"/login"} element={<Login />} />
          <Route
            path={"/receipts"}
            element={
              <RequireAuth>
                <Sidebar />
                <MainContent>
                  <React.Suspense fallback={<FullScreenSpinner />}>
                    <ReceiptsPage />
                  </React.Suspense>
                </MainContent>
              </RequireAuth>
            }
          />
          <Route
            path={"/category/:id"}
            element={
              <RequireAuth>
                <Sidebar />
                <MainContent>
                  <CategoryPage />
                </MainContent>
              </RequireAuth>
            }
          />
          <Route
            path={"/receipt/:id"}
            element={
              <RequireAuth>
                <Sidebar />
                <MainContent>
                  <ReceiptPage />
                </MainContent>
              </RequireAuth>
            }
          />
          <Route path={"*"} element={<Navigate to={"/login"} />} />
        </Routes>
      </ErrorBoundary>
    </div>
  );
}

export default App;
