import { createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { Cookies } from "react-cookie";
import { Tokens } from "../utils";
import { setAuth } from "../store/slices/auth";
import { CommonResponseModel } from "../types/common-response-model";
import { LoginReq, LoginResp, ValidateLoginReq, ValidateLoginResp } from "../types";
import { addHours } from "date-fns";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Platform from "platform";
import { CategoriesReq, Category, CategoryKeyReq, NewCategoryReq, RenameCategoryReq } from "../types/categories";
import {
  CategoryReceipts,
  CategoryReceiptsReq,
  ChangeCategoriesReceiptReq,
  Receipt,
  ReceiptKeyReq,
  ReceiptsList,
  ReceiptsListReq,
} from "../types/receipts";
import { fileGen } from "../utils/fileGen";

export function cleanCookies() {
  const cookies = new Cookies();
  cookies.remove(Tokens.accessToken);
  cookies.remove(Tokens.refreshToken);
  cookies.remove("connected");
}

const fpPromise = FingerprintJS.load();

let fingerPrint: string;

async function getFingerPrint() {
  const agent = await fpPromise;
  const info = await agent.get();

  return info.visitorId;
}

const os = Platform.os?.toString();

const baseQuery = fetchBaseQuery({
  baseUrl: "https://api.pro-chek.ru/api/v3",
  prepareHeaders: async (headers) => {
    headers.set("x-app-platform", "web");

    headers.set("Content-Type", "application/json");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Access-Control-Allow-Methods", "DELETE, POST, GET, OPTIONS");
    headers.set("Access-Control-Allow-Headers", "Content-Type, Authorization, X-Requested-With");

    if (os) {
      headers.set("x-os-version", os);
    }

    if (fingerPrint) {
      headers.set("x-device-id", fingerPrint);
    } else {
      fingerPrint = await getFingerPrint();
      if (fingerPrint) headers.set("x-device-id", fingerPrint);
    }

    const cookies = new Cookies();
    const accessToken = cookies.get(Tokens.accessToken);
    if (accessToken) {
      headers.set("Authorization", accessToken);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const cookies = new Cookies();
    const refreshToken = cookies.get(Tokens.refreshToken);
    const refreshResult = await baseQuery({ url: "/auth/refresh-tokens", body: { refreshToken }, method: "post" }, api, extraOptions);
    // @ts-ignore
    if (refreshResult.data && refreshResult.data.result) {
      // @ts-ignore
      const accessToken = refreshResult.data.result.accessToken;
      // @ts-ignore
      const refreshToken = refreshResult.data.result.refreshToken;

      cookies.set(Tokens.accessToken, accessToken, { expires: addHours(new Date(), 24) });
      cookies.set(Tokens.refreshToken, refreshToken, { expires: addHours(new Date(), 1440) });

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(setAuth(false));
      cleanCookies();
    }
  } else if (result.error && result.error.status === 403) {
    api.dispatch(setAuth(false));
    cleanCookies();
  }
  return result;
};

export const portalApi = createApi({
  reducerPath: "portalApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    initLogin: builder.mutation<CommonResponseModel<LoginResp>, LoginReq>({
      query: ({ ...payload }) => ({
        url: "auth/login",
        method: "POST",
        body: payload,
      }),
    }),
    validateLogin: builder.mutation<CommonResponseModel<ValidateLoginResp>, ValidateLoginReq>({
      query: ({ ...payload }) => ({
        url: "auth/validate",
        method: "POST",
        body: payload,
        cache: "no-cache",
      }),
    }),
    logOut: builder.mutation<CommonResponseModel<null | string>, void>({
      query: () => ({
        url: "auth/logout",
      }),
    }),
    receiptsList: builder.mutation<ReceiptsList, ReceiptsListReq>({
      query: ({ ...payload }) => ({
        url: "receipt/receipt_tape",
        method: "POST",
        body: payload,
      }),
    }),
    bindUserEmails: builder.mutation({
      query: ({ ...payload }) => ({
        url: "bind-auth-na/bind-user-emails",
        method: "POST",
        body: payload,
      }),
    }),
    userCategories: builder.mutation<CommonResponseModel<Category[]>, CategoriesReq>({
      query: ({ ...payload }) => ({
        url: "categories/tape",
        method: "POST",
        body: payload,
      }),
    }),
    newCategory: builder.mutation<CommonResponseModel<Category>, NewCategoryReq>({
      query: ({ ...payload }) => ({
        url: "categories/create",
        method: "POST",
        body: payload,
      }),
    }),
    renameCategory: builder.mutation<CommonResponseModel<Category>, RenameCategoryReq>({
      query: ({ ...payload }) => ({
        url: "categories/rename",
        method: "POST",
        body: payload,
      }),
    }),
    deleteCategory: builder.mutation<CommonResponseModel<null>, CategoryKeyReq>({
      query: ({ ...payload }) => ({
        url: "categories/delete",
        method: "POST",
        body: payload,
      }),
    }),
    getCategory: builder.mutation<CommonResponseModel<Category>, CategoryKeyReq>({
      query: ({ ...payload }) => ({
        url: "categories/get",
        method: "POST",
        body: payload,
      }),
    }),
    getCategoryReceipts: builder.mutation<CommonResponseModel<CategoryReceipts>, CategoryReceiptsReq>({
      query: ({ ...payload }) => ({
        url: "categories/receipts_tape",
        method: "POST",
        body: payload,
      }),
    }),
    downloadReceipt: builder.mutation({
      query: (id: string) => ({
        url: `receipt/fiscal_tape?receiptList=${[id]}&format=PDF`,
        method: "GET",
        responseHandler: (response) => response.blob().then((data) => fileGen(data, id)),
      }),
    }),
    receiptByKey: builder.mutation<CommonResponseModel<Receipt>, ReceiptKeyReq>({
      query: ({ ...payload }) => ({
        url: "receipt/get_by_key",
        method: "POST",
        body: payload,
      }),
    }),
    changeCategories: builder.mutation<CommonResponseModel<null>, ChangeCategoriesReceiptReq>({
      query: ({ ...payload }) => ({
        url: "receipt/change_categories",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useInitLoginMutation,
  useValidateLoginMutation,
  useReceiptsListMutation,
  useLogOutMutation,
  useBindUserEmailsMutation,
  useUserCategoriesMutation,
  useNewCategoryMutation,
  useDeleteCategoryMutation,
  useRenameCategoryMutation,
  useGetCategoryMutation,
  useGetCategoryReceiptsMutation,
  useDownloadReceiptMutation,
  useReceiptByKeyMutation,
  useChangeCategoriesMutation,
} = portalApi;
