import React from "react";
import styles from "./category-list.module.scss";
import { Checkbox } from "@flexiflow-ui-kit/common";
import { Category } from "../../../../types/categories";

interface Props {
  categoryList: Category[];
  onChangeCategories: (event: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  selectedCategories: string[];
}

export function CategoryList(props: Props) {
  const { categoryList, onChangeCategories, selectedCategories } = props;
  return (
    <div className={styles["categories-list"]}>
      {categoryList.map((category) => (
        <div className={styles.category} key={category.key}>
          <div className={styles["category-label"]}>
            <img src={category.linkIcon} className={styles["category-icon"]} alt={"icon"} />
            <div className={styles["category-name"]}>{category.name}</div>
          </div>

          <Checkbox checked={selectedCategories.includes(category.key)} onChange={(event) => onChangeCategories(event, category.key)} />
        </div>
      ))}
    </div>
  );
}
