import React from "react";
import styles from "./full-screen-spinner.module.scss";
import { Spinner } from "../ui-kit";

export function FullScreenSpinner() {
  return (
    <div className={styles["full-screen-wrapper"]}>
      <Spinner />
    </div>
  );
}
