import React from "react";
import styles from "./categories-modal.module.scss";
import { useUserCategoriesMutation } from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import { Dialog } from "../../ui-kit/Dialog";

interface Props {
  isOpen: boolean;
  closeHandler: (state: boolean) => void;
}

export function CategoriesModal(props: Props) {
  const { isOpen, closeHandler } = props;

  const navigate = useNavigate();

  const [fetchUserCategories, userCategoriesResp] = useUserCategoriesMutation();
  const userCategories = userCategoriesResp?.data?.result || [];

  React.useEffect(() => {
    fetchUserCategories({
      pagination: {
        paginationOffset: 0,
        paginationLimit: 50,
      },
      tapeCode: [5000],
    });
  }, []);

  const navigateHandler = (key: string) => {
    navigate(`/category/${key}`);
    closeHandler(false);
  };

  return (
    <Dialog className={styles.dialog} overflow={true} title={"Категории"} isOpen={isOpen} closeHandler={closeHandler}>
      <div className={styles["categories-list"]}>
        {userCategories.map((category) => (
          <button onClick={() => navigateHandler(category.key)} className={styles.category} key={category.key}>
            <img src={category.linkIcon} className={styles["category-icon"]} alt={"icon"} />
            <div className={styles["category-name"]}>{category.name}</div>
          </button>
        ))}
      </div>
    </Dialog>
  );
}
