import React from "react";
import { FallbackProps } from "react-error-boundary";
import styles from "./error-page.module.scss";
import { Button } from "@flexiflow-ui-kit/common";
import { ReactComponent as ErrorImg } from "../../../assets/images/error.svg";
import { useNavigate } from "react-router-dom";

export function ErrorPage({ resetErrorBoundary }: FallbackProps) {
  const navigate = useNavigate();

  const navigateToHomeHandler = () => {
    navigate("/");
    resetErrorBoundary();
  };

  return (
    <div className={styles.page}>
      <ErrorImg />

      <header className={styles.title}>Что-то пошло не так</header>
      <div className={styles.message}>Попробуйте обновить страницу </div>

      <div className={styles.actions}>
        <Button onClick={navigateToHomeHandler}>На главную</Button>
        <Button onClick={resetErrorBoundary}>Обновить</Button>
      </div>
    </div>
  );
}
