import React from "react";
import styles from "./button.module.scss";
import classNames from "classnames";

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  children?: React.ReactNode;
}

export function Button(props: Props) {
  const { onClick, className: externalClassName, children } = props;
  return (
    <button onClick={onClick} className={classNames(styles.button, externalClassName)}>
      {children}
    </button>
  );
}
